/**
 * Hero component that queries for data
 * with Gatsby's useStaticQuery component
 */

import React from "react"
import PropTypes from "prop-types";
import '../styles/about.scss';

import { ComeHangOut } from './images';



const About = () => {

    return (
      <div className={`section dark`} data-anchor="about" data-name="about" id="About">
          <div className={`about`}>
              <div className="about__display">
                <ComeHangOut className={"about__svg"}/>
              </div>
              <div className="about__information">
                <h1 className="about__title fade-in">Hello.</h1>
                <h3 className={'about__subtitle fade-in'}> How can I help you? </h3>
                <p className="about__para fade-in">
                    I am a web engineer in NYC who has been helping businesses grow and thrive digitally for over 4 years.
                </p>
                <p className="about__para fade-in">
                  Slick designs, outstanding performance, and software to take your business to the next level.
                    Whether you need a complex web app, a UI refresh, a plugin to augment your site's current capabilities,
                    or something more tailored to your needs, I am here to get you what you need.
                </p>
                
              </div>
           

           </div>
      </div>
    )
  }
  
  About.propTypes = {
  
  }
  
  export default About
  