const endorsements = [
    {
        name:"Edwin Sanon",
        title:"Technical Analyst - ITILv4, Citrix CCP-V, SFC, CCA-V",
        source: "linkedin"
    },{
        name:"Patrick Hines",
        title:"Senior Technical Account Manager at Microsoft",
        source: "linkedin"

    },{
        name:"Jonathan Bethune",
         title:"Expert cloud technology consultant specializing in digital transformation, devops, and training",
         source: "linkedin"

    
    },{
        name:"Michael Brown",
        title:"Global Travel and Expense Administrator at Rapid7",
        source: "linkedin"

    },{
        name:"Michelle N",
        title:"Aspiring Health Care Professional | Fitness & Nutrition Enthusiast | Spoken Word Poet",
        source: "linkedin"

    }
]


const emojis ="😃😄😁😆☺️😊😎";

export default endorsements;