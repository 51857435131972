import React from 'react';
import Project from './project';
import { Text } from './micro';

import { GatsbyImage, getImage  } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from 'gatsby';


import '../styles/comps.scss';




const logosToShow = [
    // 'bethune',

    'key_footage',
    'ethershift',
    'perfecto',
    'my_seo',

    'totalscape',
    'turfs_up',
    'valleycare',
    'wbhi',
    'top_cut',
    'kaspar',
    'amanda',
    'spark',    

    'bold',




    // 'cafe_collective',
    // 'cv',
    'db',
    // 'exec_metals',
    'satori',

    // 'heat_ba',
    'jas',
    'keiyaku',
    'live_well',
    // 'good_giggles',


    // 'sammy',


    '',
];
const logoBasePath = '../../images/comps/';




const Comps = (props) => {

    const data = useStaticQuery(graphql`
    query NonPageQuery {
      site {
        siteMetadata {
          title
        }
      }
      logos: allFile(filter: {relativeDirectory: {eq: "comps"}}) {
          edges{
              node{
                  extension
                  modifiedTime
                  dir
                  base
                  ext
                  name
                  uid,
                  childImageSharp {
                    gatsbyImageData(
                      width: 200
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }

              }
          }
      }
    }
  `)

    const { comps } = props;


    const logos = data.logos.edges;


const findLogo = (name) => {
    return Array.from( logos ).find( (item) => {
        
        return item.node.name === name;
    })

}

    return (
        <div className={'comps section'} data-anchor="why" data-name="why" id="why">
            <h1 className='comps__title'> Why Us? </h1>
            <div className='comps-grid'>


                    {logosToShow.map( (name, index ) => {
                        
                        const logo = findLogo( name );

                        if( logo?.node?.childImageSharp){

                            const image = getImage( logo.node );

                            return(
                                <div 
                                    key={ index }
                                className='comp-logo-container fade-in '
                                style={{
                                    '--anim-delay': '.8s',
                                }}
                                >
                            <div className='comp-logo-wrapper fade-in zoom-in'
                                style={{
                                    '--anim-delay': '1.1s',
                                }}
                            >
                                <GatsbyImage
                                image={ image }
                                alt="comp logo"
                                className="comp-logo"
                                />
                            </div>
                                </div>
                            )  

                        } else {
                            return name;
                        }
  

                    }
                    )}

          
            </div>

        </div>
    )
}

export default Comps;