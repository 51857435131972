/**
 * Nav component
 * Logo and menu trigger
 * navigation location and social media
 * toggle arrows 
 */


 import PropTypes from "prop-types";
 import React, { useEffect } from "react";
 import './menu-trigger.scss';
 


 
 
 const MenuTrigger = ( { isMenuVisible, toggleMenuVisibility } ) => {
 

   


   function handleMenuTrigger(e){
    const self = e.currentTarget;
    toggleMenuVisibility();
  }

  function renderClassNames( className = "" ){

    if( typeof window === 'undefined' ) return "menu-trigger";

    if( isMenuVisible ){
      return `menu-trigger fade-in active ${className} appear`
      // return menuTriggerStyles.menuTrigger
    } else if( window && window.scrollY > ( window.innerHeight / 2 ) ){
      return `menu-trigger fade-in dark ${className} appear`
    } else {
      return `menu-trigger fade-in ${className} appear`
    }
  }

  let classNames = "menu-trigger fade-in appear";

  useEffect( ( ) => {

    classNames = renderClassNames();
    return () =>{}
  })

 
   return (
   
    
            <div className={ renderClassNames( ) } onClick={e => handleMenuTrigger(e)}
            style={{
              '--anim-delay': '1.9s',
            }}
            >
             <span className="menu-trigger__span"></span>
             <span className="menu-trigger__span"></span>
             <span className="menu-trigger__span"></span>
           </div>


 
 )}
 
MenuTrigger.propTypes = {
   siteTitle: PropTypes.string,
   isMenuVisible: PropTypes.bool.isRequired,
   toggleMenuVisibility: PropTypes.func.isRequired
 }
 
MenuTrigger.defaultProps = {
   siteTitle: ``,
 }
 
 export default MenuTrigger;
 