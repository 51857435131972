/**
 * useMenu hook for triggering menu and managin state
 */


import {useState} from 'react';

const useMenu = ()=>{
    const [ isMenuVisible, setMenuVisibility] = useState( false );

    function toggleMenuVisibility(){
        setMenuVisibility( ! isMenuVisible );
    }


    return{
        isMenuVisible,
        toggleMenuVisibility
    }
}

export default useMenu;