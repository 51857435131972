/**
 * Menu component for displaying menu and navigation options
 */

import React, { useEffect, useRef } from 'react';
import PropTypes from "prop-types";

import { Text } from './micro';
import './menu.scss';



const Menu = ({isMenuVisible, toggleMenuVisibility, children })=>{

    let sectionsRef = useRef( null );
    useEffect( () => {





    }, []);


   
    
    
    return ( 
    <div className={ isMenuVisible ? 'menu__backdrop active' : 'menu__backdrop'}  onClick={ toggleMenuVisibility }> 
        <div className="menu__content"> 

        <Text 
            tag="h2"
            classNames="text--white text--play menu__title"
        >
            Menu
        </Text>
        {
           children

        }
    </div>
    </div>
    )
  

  
}

Menu.propTypes = {
    isMenuVisible: PropTypes.bool.isRequired,
    toggleMenuVisibility: PropTypes.func.isRequired,
    links: PropTypes.array.isRequired
}

export default Menu;