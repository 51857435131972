/**
 * Hero component that queries for data
 * with Gatsby's useStaticQuery component
 */

import React, {useEffect} from "react"
import PropTypes from "prop-types";
import '../styles/hero.scss';
import scrollTo from 'gatsby-plugin-smoothscroll';

import { Text } from './micro';
import Lato from './../images/svg/Lato.svg';

import {Logo} from './images';



const Hero = ({ children }) => {

  useEffect( () => {
    console.log({ Lato });
  })

    return (
      <div className={`section dark hero`} data-anchor="hero" data-name="home" id="Hero">
           <Logo height={200} width={200} 
           className={'hero__logo fade-in'}
           style={{
            '--anim-delay': '1.4s',
          }}
           ></Logo>

           <Text 
           tag={'h1'} 
           classNames='text text--play text--center hero__title fade-in'
            style={{
              '--anim-delay': '.2s',
            }}
           >
              
              Web & Software Development, NYC 
              
            </Text>
            <Text 
            tag={'h3'} 
            classNames='hero__subtitle text-white text-pop text--weight-normal text--center fade-in'
            style={{
              '--anim-delay': '.55s',
            }}
            >
            Beautiful apps you will love.
            </Text>

      </div>
    )
  }
  
  Hero.propTypes = {
    children: PropTypes.node,
  }
  
  export default Hero
  