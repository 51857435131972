import React from 'react';
import PropTypes from 'prop-types';
import  './buttons.scss';

import { Link } from 'gatsby';


const Button = ( props ) => {
    const {  children, slug,  classNames } = props;


    return(

        <Link to={`${slug}`} className={ 'button ' + classNames }>
        { children }
        </Link>
  
    )
}

Button.propTypes = {
    slug: PropTypes.string.isRequired,
    className: PropTypes.string,
    classNames: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf( PropTypes.node ),
        PropTypes.node
    ]).isRequired
}
export default Button;