/**
 * Header component
 * 
 */


import PropTypes from "prop-types"
import React, { useState, useEffect } from "react";

import { Toast, LocationIndicator,  Social } from './micro';

import scrollTo  from 'gatsby-plugin-smoothscroll';

import endorsements from "../data/endorsements";


import '../styles/footer.scss';


const Footer = ({ siteTitle, children, isMenuVisible, toggleMenuVisibility }) => {


  const [ endorsement, setEndorsement ] = useState( endorsements[ 0 ] );
  const emojis =["😃","😄","😁","😆","😊","😎"];

const renderLink = ( link, idx ) =>{
  if(!link) return "";


      return <a 
          key={idx}
          className="menu__link"
          onClick={ ()=> { 
          scrollTo(`#${link.link}`)
          toggleMenuVisibility();
      }
      
      } > {link.name} </a>
}

useEffect( () => {



  return()=>{}
})


  return (
    <footer className={['footer']}
    >
      <div className={['width-constrainer']}>
        <div className="footer__content">
        <LocationIndicator
          />
          {/* <Toast>
            <h5> { endorsement.name } </h5>
            <p>{ endorsement.title }</p>
            <p> {emojis[ Math.floor( Math.random( ) * ( emojis.length - 1 ) )]}gave a positive endorsement on LinkedIn</p>
          </Toast> */}
          <Social
            isMenuVisible={isMenuVisible}
          />
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
