/**
 * Template component for displaying project previews on index page
 */


import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import '../styles/project.scss';
import { Button  } from './micro';

import { GatsbyImage } from "gatsby-plugin-image";


const Project = ({ project, classNames }) => {


const { featuredImage, slug, subtitle, title, colours } = project;
let { blobFill } = project;

  

   const [ expanded, setExpanded ] = useState( Infinity );
   const [ activeColor, setActiveColor ] = useState( blobFill ); 

    

  const renderColours = ( colours ) => {

    for( let colour of colours ){
        return (
          <div className="project__color" style={ {backgroundColor: `${colour}`}} > 

          </div>
        )
    }

  }
  
  const handleColourClick = ( e ) => {
    let el = e.target;
    let colour = el.dataset.colour;
    setActiveColor( colour );
    
  }

  const projectRef = useRef( null );

  useEffect(() => {


    
    const fadeOptions = {
      threshold: '1',
      rootMargin: '0px 0px -60px 0px'
    }

    const fadeOnScroll = new IntersectionObserver( function( entries, fadeOnScroll ){
        entries.forEach( entry => {
          if( ! entry.isIntersecting ){
            return;
          } else {
            entry.target.style.animation = `fade-in 500ms ${ entry.target.dataset.delay } forwards ease-out`
            entry.target.classList.add( 'appear' );
            fadeOnScroll.unobserve( entry.target );
          }
        })
    }, fadeOptions );

    const elementsToObserve = projectRef.current.querySelectorAll( '.fade-in' );

    if( elementsToObserve.length ){
      elementsToObserve.forEach( element => {
        fadeOnScroll.observe( element )
      })
    }

    
    return () => {
      
    }
  }, [  project ])
  return (
    <div className={`project ${classNames? classNames: ''}`} ref={ projectRef }

    >
        <div className={'project__display'}>
     
            <div className={`project__image-container`}>
              <GatsbyImage
                image={ featuredImage.childImageSharp.gatsbyImageData }
                alt=""
                className='project__image fade-in' 
                layout="constrained"
              />
              <div className={"project__circle fade-in"} style={{backgroundColor: `${activeColor}` }}></div>
                {/* <img src={`${featuredImage.publicURL}`} className={'project__image'}/> */}
            </div>
            <div className="project__color-picker">
                {
                  colours.map( ( colour, idx ) => (
                    <button
                    key={ idx } 
                    data-colour={`${colour}`}
                    className="project__color" 
                    style={ {backgroundColor: `${colour}`}}
                    onClick={ handleColourClick }
                    > 

                    </button>
                  ))
                }
 

     
  
            </div>
        </div>
        <div className='project__information'>
            <h2 className='project__title fade-in'> { title } </h2>
            <h3 className={'project__subtitle fade-in'} data-delay="2"> { subtitle } </h3>

            

            <Button
              slug={slug}
              color={'' }
              classNames='button--outlined'
              
            >
                View More
            </Button>
        </div>
    </div>
  )
}

Project.propTypes = {
  children: PropTypes.node,
}

export default Project;
