import React from 'react'
import './brand.scss';

import { Link } from "gatsby";

const Brand = (props) => {


    return (
        <div className="brand">
            <Link className="brand__link" to={'/'}

            >
                <div className='brand__logo'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 486 486" className='brand__svg'>
                        <g  transform="translate(613.483 -30.217)">
                            <g data-name="Rectangle 1" transform="translate(-613.483 30.217)" fill="none" stroke-width="10"
                                className='brand__logo-rect'
                            >
                                <rect width="486" height="486" rx="69" stroke="none" />
                                <rect x="5" y="5" width="476" height="476" rx="64" fill="none" />
                            </g>
                            <g className="brand__logo-letters">
                                <path d="M-414.3,108.7l52.251.1.195,286.555-52.446-.1Z" transform="translate(17.607 20.868)"  />
                                <path d="M-208.4,319.342-295,396.113h-54l85.14-76.674L-346.178,245.1l15.666.195h38.921Z" transform="translate(35.305 17.188)"  />
                                <path d="M-484.5,398.066-571,321.2l55.17-.1,86.307,76.869Z" transform="translate(2.373 15.138)" />
                            </g>
                        </g>
                    </svg>

                </div>
                <div className='brand__text-container'>
                <h3
                    className="fade-in brand__text"
                    style={{
                        '--anim-delay': '1.5s',
                    }}
                >
                    Jay Bien
                </h3>
                </div>
               
            </Link>
        </div>
    )
}

export default Brand;