import React from 'react';
import Project from './project';
import { Text } from './micro';

import '../styles/projects.scss';

const Projects =  ( props ) => {

    const { projects } = props;
    
    return (
        <div className= {'projects section' }  data-anchor="projects" data-name="works" id="Projects">
                    {/* <Text 
           tag={'h1'} 
           classNames='text text--black text--play text--center'
            >
              Works
            </Text> */}
            <div className={'projects__grid'}>
                { projects
                    .sort( (a, b) => ( b.order - a.order ) )
                    .map( ( project, idx ) => {
                    return(
                        <div className={'projects__well'} key={ idx }> 
                                <Project 
                                    project={ project.node }
                                />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Projects;