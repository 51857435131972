import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './text.scss';

const Text = props => {

    const { tag, children, classNames, style } = props;

    const text = React.createElement(
        tag,
        {className: classNames, style: style},
        children
    )

    return text;
}

Text.propTypes = {
    tag: PropTypes.string.isRequired,
    classNames: PropTypes.string
}

export default Text;