import React from "react"
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';

/*
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `useStaticQuery`: https://www.gatsbyjs.com/docs/use-static-query/
 */

const Logo = ({className, style }) => {



  return <StaticImage 
          className={className} 
          style={style}
          src="./../../images/jb00.png"
          alt=""
          placeholder="blurred"
          layout="constrained"
          width={200}
          height={200}


          />
}


Logo.propTypes = {
  className:PropTypes.string,
}
export default Logo;
