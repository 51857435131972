/**
 * Header component
 * 
 */


import PropTypes from "prop-types"
import React from "react";

import { Brand, LocationIndicator, MenuTrigger, Social } from './micro';
import { Link } from "gatsby";

import scrollTo  from 'gatsby-plugin-smoothscroll';




import '../styles/header.scss';
import homeMenuData from '../data/homeMenu';
import innerMenuData from "../data/innerMenu";
import { Menu } from "./";


const Header = ({ siteTitle, children, isMenuVisible, toggleMenuVisibility, showHomeMenu }) => {

const renderLink = ( link, idx ) =>{
  if(!link) return "";


      return <a 
          key={idx}
          className="menu__link"
          onClick={ ()=> { 
          scrollTo(`#${link.link}`)
          toggleMenuVisibility();
      }
      
      } > {link.name} </a>
}

const renderPageLink = ( link, idx ) =>{
  if( !link ) return null;

  return(
    <Link to={ link.link}>
        <a>{ link.name }  </a>
    </Link>
  )
}

  return (
    <header className={['header']}
    >
      <div className={['width-constrainer']}>
        <div className="header__content">
        <Brand />
        <MenuTrigger
          isMenuVisible={isMenuVisible}
          toggleMenuVisibility={toggleMenuVisibility}
        />
        <Menu
          isMenuVisible={isMenuVisible}
          toggleMenuVisibility={toggleMenuVisibility}
          links={[]}
        >
          {!showHomeMenu && innerMenuData && innerMenuData.map( (item, index ) => {
            return renderPageLink( item , index );
          }) }
          {showHomeMenu && homeMenuData && homeMenuData.map((item, index) => {
            return renderLink(item, index)
          })}
        </Menu>
        </div>

      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
