/**
 * Nav bottom component
 * navigation location and social media
 * toggle arrows 
 */


 import PropTypes from "prop-types";
 import React, { useEffect } from "react";
 
 
 
 import { SOCIALS } from '../../constants';
 import { Dribbble, Github, Instagram, LinkedIn } from '../../components/icons';
 import './social-media.scss';

 
 
 const SocialMedia = ({ isMenuVisible }) => {

 
     function renderIcon(name, classNames){
         switch(name){
             case 'dribbble':
                 return <Dribbble className={ classNames }></Dribbble>;
             case "github":
                 return <Github className={ classNames }></Github>;
             case "instagram":
                 return <Instagram className={ classNames }></Instagram>;
             case "linkedin":
                 return <LinkedIn className={ classNames }></LinkedIn>;
             default:
                 return <></>;
         }
     }
     function renderClassNames(){

        if( typeof window === 'undefined' ) return "social-media ";
        if( isMenuVisible ){
          return 'social-media active '
        } else if( window.scrollY > window.innerHeight / 11){
          return 'social-media dark '
        } else {
          return 'social-media '
        }
      }


      let classNames = "social-media";

      useEffect( () => {
        classNames = renderClassNames();
      })
 
   return (
   <div className={ 'fade-in appear ' + renderClassNames() }
   style={{
    '--anim-delay': '2.5s',
  }}
   >         
         {SOCIALS.map(social=>{
 
             return(
                 <div className={`social-media__link `} key={`${social.name}`}
                   >
                     <a href={`${social.url}`} target="_tab">
                         {renderIcon(social.name.toLowerCase(), "social-media__icon")}
                     </a>
                 </div>
             )
         })}
 
   </div>
 )}
 
SocialMedia.propTypes = {
   siteTitle: PropTypes.string,
 }
 
 SocialMedia.defaultProps = {
   siteTitle: ``,
 }
 
 export default SocialMedia;
 