/**
 * Location Indicator component for displaying location of navigation 
 */

import React from 'react';
import './location-indicator.scss';


const LocationIndicator = ({props})=>{

    return (
        <div className="location-indicator">
            {/* <h3 className="location-indicator__text"> Hero </h3> */}
        </div>
    )

}

export default LocationIndicator;