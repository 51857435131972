/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header";
import Footer from './footer';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'



import useMenu from "../hooks/useMenu";




import "../styles/layout.scss"

const Layout = ( props ) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)


  const { children, showHomeMenu } = props;
  const { isMenuVisible, toggleMenuVisibility } = useMenu();


  useEffect( () => { 



    
    // Initialize an agent at application startup.
    const fpPromise = FingerprintJS.load({
      token: 'ZGJmN18NxSKw1zVOupQy'
    })
    
    // Get the visitor identifier when you need it.
    fpPromise
      .then(fp => fp.get())
      .then(result => console.log(result.visitorId))
      .catch(error =>{ 

        //todo fail silently or try again later
        console.error(error)}
        
        )

    return () => {}
  }, [])



  return (
    <>
      <div className="width-constrainer">
        <Header siteTitle={data.site?.siteMetadata?.title || `Title`}
          isMenuVisible={isMenuVisible}
          toggleMenuVisibility={toggleMenuVisibility}
          showHomeMenu={showHomeMenu}
        />

        <main>{children}</main>
        <Footer>


          </Footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
