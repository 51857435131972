/**
 * 
 */


 import { Link } from "gatsby";
 import PropTypes from "prop-types"
 import React from "react"
 
 
 import '../styles/dropdown.scss';
 
 const Dropdown = ( ) => (
   <nav className={[ 'dropdown' ]}  >
     
   </nav>
 )
 
Dropdown.propTypes = {
   siteTitle: PropTypes.string,
 }
 
Dropdown.defaultProps = {
   siteTitle: ``,
 }
 
 export default Dropdown;
 